import React from "react";
import {
  Input,
  Box,
  HStack,
  VStack,
  Divider,
  Flex,
  Switch,
  FormLabel,
  color,
} from "@chakra-ui/react";

import "./styles.scss";
import dayjs from "dayjs";

const Summary = ({ setDetails, details, activity }) => {
  const { name, surname, city, vat, country, pobox, address } =
    details.personalDetails;
  const { slots } = details.participants;
  const { startDate, endDate } = details.schedule;
  const { email, phone, comments } = details.contacts;
  const billing = details.billing;

  let totalPrice;
  if (slots && activity?.price) {
    totalPrice = slots * activity?.price;
  }

  //lets calculate the value of the reservation partial based on the total price
  let partialPayment;
  if (activity?.initPercentage && activity?.initPercentage > 0) {
    partialPayment = (activity?.initPercentage / 100) * totalPrice;
  }

  return (
    <Box width={"100%"} className={"summary"}>
      <VStack>
        <Box className="summary-title">Detalhes pessoais</Box>
        <HStack width={"100%"}>
          <Box width={"100%"}>
            <label>Nome</label>
            <Box className={"summary-value"}>{name}</Box>
          </Box>
          <Box width={"100%"}>
            <label>Sobrenome</label>
            <Box className={"summary-value"}>{surname}</Box>
          </Box>
          <Box width={"100%"}>
            <label>NIF</label>
            <Box className={"summary-value"}>{vat}</Box>
          </Box>
        </HStack>
        <HStack width={"100%"}>
          <Box width={"100%"}>
            <label>Morada</label>
            <Box className={"summary-value"}>{address}</Box>
          </Box>
        </HStack>
        <HStack width={"100%"}>
          <Box width={"100%"}>
            <label>Codigo Postal</label>
            <Box className={"summary-value"}>{pobox}</Box>
          </Box>
          <Box width={"100%"}>
            <label>Cidade</label>
            <Box className={"summary-value"}>{city}</Box>
          </Box>
          <Box width={"100%"}>
            <label>País</label>
            <Box className={"summary-value"}>{country}</Box>
          </Box>
        </HStack>
        <Divider orientation="horizontal" padding={"1em 0"} />
        <Box className="summary-title">Reserva</Box>
        <HStack width={"100%"} alignItems={"flex-start"}>
          <Box width={"100%"}>
            <label>Lugares</label>
            <Box className={"summary-value"}>{slots}</Box>
          </Box>
          <Box width={"100%"}>
            <label>Preço</label>
            <Box className={"summary-value"}>{totalPrice || 0}€</Box>
          </Box>
          <Box width={"100%"}>
            <label>Inicia</label>
            <Box className={"summary-value"}>
              {dayjs(startDate).format("DD-MM-YYYY HH:mm")}
            </Box>
          </Box>
          <Box width={"100%"}>
            <label>Termina</label>
            <Box className={"summary-value"}>
              {dayjs(endDate).format("DD-MM-YYYY HH:mm")}
            </Box>
          </Box>
        </HStack>

        <Divider orientation="horizontal" padding={"1em 0"} />
        <Box className="summary-title">Contactos</Box>
        <HStack width={"100%"}>
          <Box width={"100%"}>
            <label>Email</label>
            <Box className={"summary-value"}>{email}</Box>
          </Box>
          <Box width={"100%"}>
            <label>Telefone</label>
            <Box className={"summary-value"}>{phone}</Box>
          </Box>
        </HStack>
        <Divider orientation="horizontal" padding={"1em 0"} />
        {(billing.company ||
          billing.vat ||
          billing.address ||
          billing.pobox ||
          billing.city ||
          billing.country) && (
          <>
            {" "}
            <Box className="summary-title">Faturação</Box>
            <HStack width={"100%"}>
              {billing.company && (
                <Box width={"100%"}>
                  <label>Empresa</label>
                  <Box className={"summary-value"}>{billing.company}</Box>
                </Box>
              )}
              {billing.vat && (
                <Box width={"100%"}>
                  <label>NIF</label>
                  <Box className={"summary-value"}>{billing.vat}</Box>
                </Box>
              )}
            </HStack>
            {billing.address && (
              <HStack width={"100%"}>
                <Box width={"100%"}>
                  <label>Morada</label>
                  <Box className={"summary-value"}>{billing.address}</Box>
                </Box>
              </HStack>
            )}
            <HStack width={"100%"}>
              {billing.pobox && (
                <Box width={"100%"}>
                  <label>Codigo Postal</label>
                  <Box className={"summary-value"}>{billing.pobox}</Box>
                </Box>
              )}
              {billing.city && (
                <Box width={"100%"}>
                  <label>Cidade</label>
                  <Box className={"summary-value"}>{billing.city}</Box>
                </Box>
              )}
              {billing.country && (
                <Box width={"100%"}>
                  <label>País</label>
                  <Box className={"summary-value"}>{billing.country}</Box>
                </Box>
              )}
            </HStack>
          </>
        )}
        {slots >= 15 && activity?.preferedPayment !== "mb" && (
          <Box>
            <Box className="summary-title">Pagamento</Box>

            <Flex
              alignItems={"center"}
              mt={"1em"}
              p={"1em"}
              background={"#fafafa"}
              borderRadius={"5px"}
              border={"1px dotted #e0e0e0"}
            >
              <Switch
                id="paymentType"
                size="md"
                mr={"1em"}
                colorScheme={"green"}
                isChecked={billing?.paymentType === "partial"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setDetails({
                      ...details,
                      billing: { ...details.billing, paymentType: "partial" },
                    });
                  } else {
                    setDetails({
                      ...details,
                      billing: { ...details.billing, paymentType: "total" },
                    });
                  }
                }}
              />
              <FormLabel
                htmlFor="paymentType"
                mt={".5em"}
                fontSize={"1em!important"}
                lineHeight={1.3}
                color={"#000"}
              >
                Quero pagar apenas o sinal de <strong>{partialPayment}€</strong>{" "}
                como a reserva e o restante no final da atividade
              </FormLabel>
            </Flex>
            <Divider orientation="horizontal" padding={"1em 0"} />
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default Summary;
