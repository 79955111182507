import {
  Box,
  useToast,
  Progress,
  Button,
  Switch,
  Flex,
  FormLabel,
} from "@chakra-ui/react";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { setGlobalState, getGlobalState, useGlobalState } from "../store";

import Activity from "../components/Activity/Activity";
import GroupActivity from "../components/Activity/GroupActivity";
import NoActivity from "../components/Activity/NoActivity";
import Thankyou from "../components/Activity/Thankyou";
import Card from "../components/Card/Card";
import ContentSide from "../components/ContentSide/ContentSide";

//Forms
import Billing from "../components/Forms/Billing/Billing";
import Contacts from "../components/Forms/Contacts/Contacts";
import DatePicker from "../components/Forms/DatePicker/DatePicker";
import Navigation from "../components/Forms/Navigation/Navigation";
import Participants from "../components/Forms/Participants/Participants";
import PersonalDetails from "../components/Forms/PersonalDetails/PersonalDetails";
import Summary from "../components/Forms/Summary/Summary";

const Main = (props) => {
  let { id, bookid } = useParams();
  const toast = useToast();
  /**
   * State set
   */
  const [details, setDetails] = useState({
    activity: id,
    bookId: null,
    personalDetails: {
      name: "",
      vat: "",
      surname: "",
      address: "",
      city: "",
      local: "",
      country: "",
      institution: "",
      pobox: "",
    },
    participants: { slots: "1", list: [] },
    schedule: {
      startDate: "",
      endDate: "",
      dateArray: [],
    },
    billing: {
      address: "",
      company: "",
      country: "",
      pobox: "",
      city: "",
      vat: "",
      paymentType: "",
    },
    contacts: {
      email: "",
      phone: "",
      comments: "",
    },
  });

  const [activity, setActivity] = useState({
    title: "Nome da Atividade",
    location: "Local da Atividade",
    description:
      "Tot vocant extremum gravis postulant video optimus potuimus harum erroribus dicent genus eas ducimus rectas. Tot vocant eant video optimus potuimus harum erroribus dicent genus eas ducimus rectas",
  });
  const [hasAgreed, setHasAgreed] = useState(false);
  const [isGroup, setIsGroup] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [loggedIn, setLoggedIn] = useState(null);
  const [step, setCurrStep] = useState(0);
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  //loginMycascais
  useEffect(() => {
    async function getLogin() {
      if (!id && !bookid) return;
      //handle login
      if (window.MyCascaisCookie && !loggedIn) {
        try {
          const response = await axios.post(`/auth/mycascais/`, {
            tid: window.MyCascaisCookie,
          });
          if (response.data && response.data.token) {
            const userData = response.data;
            setLoggedIn(userData);
            setDetails({
              ...details,
              personalDetails: {
                institution: userData.institution,
                name: userData.firstName,
                vat: userData.vat,
                surname: userData.lastName,
                address: userData.address,
                city: userData.city,
                local: userData.local,
                country: userData.country,
                pobox: userData.poBox,
              },
              contacts: {
                ...details.contacts,
                email: userData.email,
                phone: userData.phone,
              },
            });
          } else if (response?.data?.myCascais) {
            setLoggedIn({
              status: "New user",
              myCascais: response?.data?.myCascais,
            });
          } else {
            loginMyCascais();
          }

          //handle logout
        } catch (error) {
          console.log(error);
          // loginMyCascais();
          setLoggedIn(null);
        }
      } else if (!loggedIn) {
        // loginMyCascais();
      }
    }
    getLogin();
    return () => {};
  }, [loggedIn, window.MyCascaisCookie]);
  /**
   * Submit reservation
   */

  const submitReservation = async () => {
    try {
      let myCascais = loggedIn.myCascais || details?.contacts?.email;
      setSaving(true);
      const setData = await axios.post(`booking/gtn`, {
        ...details,
        myCascais: myCascais,
      });
      if (setData.data) {
        toast({
          title: "Reserva registada com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setSaved(true);
        setBookingDetails(setData.data);
      } else {
        toast({
          title:
            "Aconteceu um erro a salvar a sua reserva, por-favor tente novamente",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      setSaving(false);
    } catch (error) {
      setSaving(false);
      toast({
        title:
          "Aconteceu um erro a salvar a sua reserva, por-favor tente novamente",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  /**
   * Get activity data
   */
  useEffect(() => {
    async function fetchData() {
      try {
        if (!id) throw new Error("no id defined");
        const activityData = await axios.get(`booking/activitygroup/${id}`);
        if (!activityData.data) throw new Error("no data");
        if (
          activityData.data?.groupActivity &&
          !activityData.data?.group &&
          activityData.data?.groupActivity?.length > 0
        ) {
          setIsGroup(activityData.data?.groupActivity);
        }
        if (activityData.data?.group) {
          setGroupId(activityData.data?.group);
        }
        setActivity(activityData.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    // fetchGroupData(id);
    return () => {};
  }, [id]);

  /**
   * Get Activity group data
   */
  // async function fetchGroupData(id) {
  //   try {
  //     const activitieslData = await axios.post(`booking/group/${id}`);

  //     if (!activitieslData.data) throw new Error("no data");
  //     setIsGroup(activitieslData.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  /**
   * check users availability
   */
  const areAllUserVatsAvailable = async (vats, date, endDate) => {
    try {
      const areUsersAvailable = await axios.post(`booking/isavailable`, {
        vats: vats,
        date: date,
        endDate: endDate,
      });
      return areUsersAvailable?.data?.available;
    } catch (error) {
      console.log(error);
      return true;
    }
  };

  /**
   * Get booking data
   * @param {*} bookid
   */
  useEffect(() => {
    async function fetchData() {
      try {
        if (details.bookId) return;
        setDetails({ ...details, bookId: bookid });
        if (!bookid) return;
        const bookingData = await axios.get(`booking/${bookid}`);
        if (!bookingData.data) throw new Error("no data");
        const bookingDetails = bookingData.data.data;
        setDetails({
          ...details,
          activity: bookingData?.data?.data?.activity?._id || "",
          personalDetails: {
            name: bookingDetails?.owner?.firstName || "",
            vat: bookingDetails?.owner?.vat || "",
            surname: bookingDetails?.owner?.lastName || "",
            address: bookingDetails?.owner?.address || "",
            city: bookingDetails?.owner?.city || "",
            local: bookingDetails?.owner?.local || "",
            country: bookingDetails?.owner?.country || "",
            institution: bookingDetails?.owner?.institution || "",
            pobox: bookingDetails?.owner?.poBox || "",
          },
          participants: {
            slots: bookingDetails?.seats || "",
            list: bookingDetails?.participants?.list || [],
          },
          billing: {
            ...(bookingDetails?.billing || {}),
          },
          contacts: {
            email: bookingDetails?.owner?.email || "",
            phone: bookingDetails?.owner?.phone || "",
            comments: "",
          },
          schedule: {
            startDate: dayjs(bookingDetails?.date).toDate() || "",
            endDate: dayjs(bookingDetails?.dateEnd).toDate() || "",
            dateArray: bookingDetails?.schedule?.dateArray,
          },
          bookId: bookid,
          status: bookingDetails?.status,
        });
        setActivity(bookingData?.data?.data?.activity || activity);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    return () => {};
  }, [bookid]);

  /**
   * check mandatory fields before continue
   * @returns
   */
  const setStep = async (val) => {
    //if we go back
    if (val < step) {
      setCurrStep(val);
      return true;
    }
    //if forward
    if (step === 0) {
      if (
        details.personalDetails.name === "" ||
        !details.personalDetails.local ||
        details.personalDetails.local === "" ||
        details.personalDetails.surname === "" ||
        details.personalDetails.vat === ""
        // details.personalDetails.address === "" ||
        // details.personalDetails.pobox === ""
      ) {
        toast({
          title: "Preencha todos os campos obrigatórios",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return false;
      } else {
        setCurrStep(val);
      }
    } else if (step === 1) {
      if (
        !details.participants.slots ||
        details.participants.slots === "" ||
        Number(details.participants.slots) < 1
      ) {
        toast({
          title: "Preencha todos os campos com a informação dos participantes",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return false;
      } else {
        if (
          activity.minSlots &&
          activity.minSlots !== "" &&
          activity.minSlots > Number(details.participants.slots)
        ) {
          toast({
            title:
              "O número de participantes tem de ser maior que " +
              activity.minSlots,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return false;
        }

        const participants = details?.participants?.list;
        if (
          Number(details.participants.slots) !== participants.length &&
          !activity?.simpleReservation
        ) {
          toast({
            title: "É necessário preencher os detalhes dos participantes",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return false;
        }
        //lets check if we have participants with no data
        const invalid = participants?.filter((p) => {
          if (activity?.variations && activity?.variations?.length) {
            if (p?.variation === "" || !p?.variation) return true;
          }
          if (activity?.simpleReservation) return false;
          if (p?.age === "" || !p?.age) return true;
          if (p?.name === "" || !p?.name) return true;
          if (activity?.requiredNIF) {
            if (p?.nif === "" || !p?.nif) return true;
          }

          return false;
        });

        if (
          activity?.variations &&
          Number(details.participants.slots) !== participants.length
        ) {
          toast({
            title: "É necessário preencher os detalhes dos participantes",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return false;
        }

        if (invalid.length > 0) {
          toast({
            title:
              "Preencha todos os campos dos participantes são obrigatórios",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return false;
        }

        if (activity?.ageControl && !activity?.simpleReservation) {
          const minAge = activity?.minAge || 0;
          const maxAge = activity?.maxAge || 0;
          const participants = details.participants.list;

          const invalid = participants.filter((p) => {
            if (p?.age === "" || !p?.age) return true;
            const ageInYears = dayjs().diff(p?.age, "years");
            if (minAge && maxAge && minAge !== "" && maxAge !== "")
              return ageInYears < minAge || ageInYears > maxAge;
            if ((minAge && !maxAge) || maxAge === "")
              return ageInYears < minAge;
            if ((!minAge && maxAge) || minAge === "")
              return ageInYears > maxAge;
            return false;
          });

          if (invalid.length > 0) {
            toast({
              title: `A idade dos participantes tem de estar entre ${minAge} e ${maxAge} anos`,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            return false;
          }
        }
        setCurrStep(val);
      }
    } else if (step === 3) {
      if (details.contacts.email === "" || details.contacts.phone === "") {
        toast({
          title: "Preencha todos os campos obrigatórios",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setCurrStep(val);
      }
    } else if (step === 2) {
      if (
        details.schedule.startDate === "" ||
        details.schedule.endDate === ""
      ) {
        toast({
          title: "Tem de escolher uma data",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        //check if all user vats are available for this timeslot
        let participantsNif = [details.personalDetails.vat];
        details.participants.list.forEach((p) => {
          if (p.nif && p.nif !== "") {
            participantsNif.push(p.nif);
          }
        });
        const isAvailable = await areAllUserVatsAvailable(
          participantsNif,
          details.schedule.startDate,
          details.schedule.endDate
        );
        if (isAvailable) {
          setCurrStep(val);
        } else {
          toast({
            title:
              "Um dos participantes já têm uma ativa para este horario, por favor escolha outro horario ou contacte-nos",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    } else if (step === 3) {
      if (details.contacts.email === "" || details.contacts.phone === "") {
        toast({
          title: "Preencha todos os campos obrigatórios",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setCurrStep(val);
      }
    } else if (step === 4) {
      // if (
      //   details.billing.address === "" ||
      //   details.billing.pobox === "" ||
      //   details.billing.city === "" ||
      //   details.billing.country === "" ||
      //   details.billing.vat === ""
      // ) {
      //   toast({
      //     title: "Preencha todos os campos obrigatórios",
      //     status: "error",
      //     duration: 9000,
      //     isClosable: true,
      //   });
      // } else {
      //   setCurrStep(val);
      // }
      setCurrStep(val);
    }
  };
  /**
   * Switch between form steps
   * @returns
   */
  const formStep = () => {
    //pendente or confirmada can only change dados de faturaçao
    if (details?.status === 1) {
      const bookingStepsFact = {
        0: (
          <>
            <Billing details={details} setDetails={setDetails} />
            <Navigation step={step} setStep={setStep}>
              Confimar Dados
            </Navigation>
          </>
        ),
        1: (
          <>
            <Summary details={details} setDetails={setDetails} />
            <Flex alignItems={"center"} mt={"1em"}>
              <Switch
                id="liAceito"
                size="md"
                mr={"1em"}
                colorScheme={"green"}
                isChecked={hasAgreed}
                onChange={(e) => {
                  if (e.target.checked) {
                    setHasAgreed(true);
                  } else {
                    setHasAgreed(false);
                  }
                }}
              />
              <FormLabel htmlFor="liAceito" mt={".5em"}>
                Li e aceito os{" "}
                <a
                  href="/static/condicoes.pdf"
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  Termos e Condições
                </a>{" "}
                e a{" "}
                <a
                  style={{
                    textDecoration: "underline",
                  }}
                  href="https://www.cascais.pt/politica-de-privacidade-e-tratamento-de-dados-pessoais"
                  target="_blank"
                >
                  politica de privacidade
                </a>
              </FormLabel>
            </Flex>
            <Navigation
              step={step}
              setStep={setStep}
              onClick={() => {
                if (!hasAgreed) {
                  toast({
                    title: "Por favor aceite os termos e condições",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                  return false;
                }
                return !saving && submitReservation();
              }}
            >
              Enviar Reserva
            </Navigation>
          </>
        ),
      };

      return bookingStepsFact;
    } else if (details?.status === 12) {
      //pendente can chnge participants and faturacao
      const bookingStepsPendente = {
        0: (
          <>
            <Participants details={details} setDetails={setDetails} />
            <Navigation step={step} setStep={setStep}>
              Escolher Data
            </Navigation>
          </>
        ),
        1: (
          <>
            <DatePicker
              bookId={details.bookId}
              seats={details.participants.slots}
              dates={details.schedule}
              activity={details.activity}
              activityData={activity}
              details={details}
              setDetails={setDetails}
            />
            <Navigation step={step} setStep={setStep}>
              Preencher contactos
            </Navigation>
          </>
        ),
        2: (
          <>
            <Billing details={details} setDetails={setDetails} />
            <Navigation step={step} setStep={setStep}>
              Confimar Dados
            </Navigation>
          </>
        ),
        3: (
          <>
            <Summary details={details} setDetails={setDetails} />
            <Flex alignItems={"center"} mt={"1em"}>
              <Switch
                id="liAceito"
                size="md"
                mr={"1em"}
                colorScheme={"green"}
                isChecked={hasAgreed}
                onChange={(e) => {
                  if (e.target.checked) {
                    setHasAgreed(true);
                  } else {
                    setHasAgreed(false);
                  }
                }}
              />
              <FormLabel htmlFor="liAceito" mt={".5em"}>
                Li e aceito os{" "}
                <a
                  href="/static/condicoes.pdf"
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  Termos e Condições
                </a>{" "}
                e a{" "}
                <a
                  style={{
                    textDecoration: "underline",
                  }}
                  href="https://www.cascais.pt/politica-de-privacidade-e-tratamento-de-dados-pessoais"
                  target="_blank"
                >
                  politica de privacidade
                </a>
              </FormLabel>
            </Flex>
            <Navigation
              step={step}
              setStep={setStep}
              onClick={() => {
                if (!hasAgreed) {
                  toast({
                    title: "Por favor aceite os termos e condições",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                  return false;
                }
                return !saving && submitReservation();
              }}
            >
              Enviar Reserva
            </Navigation>
          </>
        ),
      };

      return bookingStepsPendente;
    }

    //normal process
    return {
      0: (
        <>
          <PersonalDetails details={details} setDetails={setDetails} />
          <Navigation step={step} setStep={setStep}>
            Escolher Lugares
          </Navigation>
        </>
      ),
      1: (
        <>
          <Participants
            details={details}
            activity={activity}
            setDetails={setDetails}
          />
          <Navigation step={step} setStep={setStep}>
            Escolher Data
          </Navigation>
        </>
      ),
      2: (
        <>
          <DatePicker
            bookId={details.bookId}
            seats={details.participants.slots}
            dates={details.schedule}
            activity={details.activity}
            activityData={activity}
            details={details}
            setDetails={setDetails}
          />
          <Navigation step={step} setStep={setStep}>
            Preencher contactos
          </Navigation>
        </>
      ),
      3: (
        <>
          <Contacts details={details} setDetails={setDetails} />
          <Navigation step={step} setStep={setStep}>
            Dados de Faturação
          </Navigation>
        </>
      ),
      4: (
        <>
          <Billing details={details} setDetails={setDetails} />
          <Navigation step={step} setStep={setStep}>
            Confimar Dados
          </Navigation>
        </>
      ),
      5: (
        <>
          <Summary
            details={details}
            activity={activity}
            setDetails={setDetails}
          />
          <Flex alignItems={"center"} mt={"1em"}>
            <Switch
              id="liAceito"
              size="md"
              mr={"1em"}
              colorScheme={"green"}
              isChecked={hasAgreed}
              onChange={(e) => {
                if (e.target.checked) {
                  setHasAgreed(e.target.checked);
                } else {
                  setHasAgreed(false);
                }
              }}
            />
            <FormLabel htmlFor="liAceito" mt={".5em"}>
              Li e aceito os{" "}
              <a
                href="/static/condicoes.pdf"
                target="_blank"
                style={{
                  textDecoration: "underline",
                }}
              >
                Termos e Condições
              </a>{" "}
              e a{" "}
              <a
                style={{
                  textDecoration: "underline",
                }}
                href="https://www.cascais.pt/politica-de-privacidade-e-tratamento-de-dados-pessoais"
                target="_blank"
              >
                politica de privacidade
              </a>
            </FormLabel>
          </Flex>
          <Navigation
            step={step}
            setStep={setStep}
            onClick={() => {
              if (!hasAgreed) {
                toast({
                  title: "Por favor aceite os termos e condições",
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
                return false;
              }
              return !saving && submitReservation();
            }}
          >
            {saving ? "A salvar..." : "Enviar Reserva"}
          </Navigation>
        </>
      ),
    };
  };

  const loginNoCascais = async (e) => {
    try {
      //lets generate a unique cascaisid fake code
      // const cascaisId = Math.random().toString(36).substring(7);
      setLoggedIn({
        status: "New user",
        myCascais: null,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const loginMyCascais = async (e) => {
    try {
      if (!window.MyCascaisLogin)
        toast({
          title: "My cascais not available",
          status: "warning",
          position: "bottom",
        });
      window.location = window.MyCascaisLogin;
    } catch (e) {}
  };

  /**
   * show saved data
   */
  if (saved) {
    return (
      <ContentSide image={activity && activity.image}>
        <Thankyou bookingDetails={bookingDetails} groupId={groupId} />
      </ContentSide>
    );
  }

  if (isGroup) {
    return <GroupActivity isGroup={isGroup} activity={activity} />;
  }
  /**
   * If no Activity is selected, show NoActivity component
   */
  if (!activity || (!id && !bookid)) {
    return <NoActivity />;
  }
  /**
   * If no Activity is selected, show NoActivity component
   */
  if (
    // !window.MyCascaisCookie &&
    !loggedIn
    // || (loggedIn && loggedIn.status !== "New user")
  ) {
    return (
      <ContentSide image={activity && activity.image}>
        <Box width={"100%"} padding={"0 2em"}>
          <Activity activity={activity} details={details} groupId={groupId} />
        </Box>
        <Box width={"100%"}>
          <Card>
            <Box className={"local"} textAlign={"center"}>
              Para proceder com o pedido e ter acesso à sua area pessoal deve
              pode autenticar-se com o serviço MyCascais.
            </Box>
            <Progress isIndeterminate />
            <Button
              onClick={(e) => loginMyCascais(e)}
              type="submit"
              bg="#000"
              w="100%"
              h="45"
              mb="5px"
              mt={"1em"}
              color="white"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
            >
              Entrar com My Cascais
            </Button>
            <Button
              onClick={(e) => loginNoCascais(e)}
              type="submit"
              variant="outline"
              w="100%"
              h="45"
              mb="5px"
              mt={"0em"}
              color="black"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
            >
              Avançar sem conta
            </Button>
          </Card>
        </Box>
      </ContentSide>
    );
  }

  /**
   * Lets render the form based on the step
   */
  return (
    <ContentSide image={activity && activity.image}>
      <Box width={"100%"} padding={"0 2em"}>
        <Activity activity={activity} details={details} />
        <Progress
          mt={"1em"}
          borderRadius={"6px"}
          value={(step / 5) * 100}
          colorScheme="green"
        />
      </Box>
      <Box width={"100%"}>
        <Card>{formStep()[step]}</Card>
      </Box>
    </ContentSide>
  );
};

export default Main;
